

export function labelEnergieTräger(value) {
    const labelEnergieträgerText =  [
        "Wärmepumpenstromverbrauch [kWh]",
        "Heizstromverbrauch [kWh]",
        "Gasverbrauch [kWh]",
        "Ölverbrauch [Liter]",
        "Holzverbrauch [kg]",
        "Fernwärmeverbrauch [MWh]",
    ]
    return value !== null ?  labelEnergieträgerText[value] : "Kein Energieträger ausgewählt"
}
export function energieTrägerText(value) {
    const labelEnergieträgerText =  [
            "Wärmepumpe",
            "Strom",
            "Gas",
            "Öl",
            "Holz",
            "Fernwärme",
        ]
    return labelEnergieträgerText[parseInt(value)]
}
export function boolText(val) {
    return val ? "Ja" : "Nein"
}
export function  textDachgeschossBewohnt(val) {
    let text = "";
    switch(val) {
        case "BisInDieSpitze": text = "Bis in die Spitze"
            break;
        case "JaOhneSpitze": text = "Ja, ohne Spitze"
            break;
        case "Nein": text = "Nein"
            break;
    }
    return text
}