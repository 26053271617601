<template>
  <div class="spinner overlay" :hidden="!loading">
    <div class="overlay-content">
      <div class="spinner-text">Szenarien werden berechnet</div>
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background:rgb(255,255,255,1);display:none; margin: auto auto 10px;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="rotate(180 50 50)"><rect x="15" y="15" width="10" height="40" fill="#abbd81">
          <animate attributeName="height" values="50;70;30;50" keyTimes="0;0.33;0.66;1" dur="1s" repeatCount="indefinite" calcMode="spline" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" begin="-0.4s"></animate>
        </rect><rect x="35" y="15" width="10" height="40" fill="#f8b26a">
          <animate attributeName="height" values="50;70;30;50" keyTimes="0;0.33;0.66;1" dur="1s" repeatCount="indefinite" calcMode="spline" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" begin="-0.2s"></animate>
        </rect><rect x="55" y="15" width="10" height="40" fill="#f47e60">
          <animate attributeName="height" values="50;70;30;50" keyTimes="0;0.33;0.66;1" dur="1s" repeatCount="indefinite" calcMode="spline" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" begin="-0.6s"></animate>
        </rect><rect x="75" y="15" width="10" height="40" fill="#e15b64">
          <animate attributeName="height" values="50;70;30;50" keyTimes="0;0.33;0.66;1" dur="1s" repeatCount="indefinite" calcMode="spline" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" begin="-1s"></animate>
        </rect></g>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:#fff;display:none;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" r="32" stroke-width="8" stroke="#e15b64" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
          <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
        </circle>
        <circle cx="50" cy="50" r="23" stroke-width="8" stroke="#f8b26a" stroke-dasharray="36.12831551628262 36.12831551628262" stroke-dashoffset="36.12831551628262" fill="none" stroke-linecap="round">
          <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;-360 50 50"></animateTransform>
        </circle>
      </svg>
    </div>
  </div>
  <div class="info overlay" @click="showInfoOverlay(false)" :class="{ active: showInfo }">
    <div class="overlay-content">
      <div class="table-div">
        <h2>Typische Maßnahmen für die energetische Sanierung</h2>
        <table>
          <tr>
            <td>Gewerke und Bauteile</td>
            <td>Investitionen vor Förderung</td>
            <td>Hinweise</td>
          </tr>
          <tr>
            <td>Heizung</td>
            <td>35 bis 65 T€</td>
            <td>
              <ul>
                <li>Heizungstausch zu Wärmepumpe bzw. Wärmepumpenhybridsystem</li>
                <li>Ggf.  Sanierung oder Neuausgestaltung der Verrohrung im Gebäude sowie der Heizkörper</li>
                <li>Die konkrete Ausgestaltung des Heizungs-systems und ggf. Nutzung anderer  Lösungen (Fernwärme, Nahwärme, Holz, ...) ist mit Fachplanern in Detail abzustimmen</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Dach</td>
            <td>250 bis 350 €/m²</td>
            <td>
              <ul>
                <li> Dämmung mit  16-18 bis 12-14 cm entsprechend Effizienzhausstandard EH 55 bis 85</li>
                <li>Ggf. Ersatz von Dachfenstern, Sanierung von Dachgauben, ...</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Oberste Ge-schossdecke</td>
            <td>150 bis 200 €/m²</td>
            <td>
              <ul>
                <li>Dämmung mit  14-16 bis 10-12 cm entsprechend Effizienzhausstandard EH 55 bis 85</li>
                <li>Ggf. Dämmung hin zu angrenzenden Wänden z.B im Treppenhaus</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Außenwände</td>
            <td>250 bis 350 €/m²</td>
            <td>
              <ul>
                <li>Dämmung mit 14-16 bis 12-14 cm entsprechend Effizienzhausstandard EH 55 bis 85</li>
                <li>Ggf. Dämm- und Sanierungsmaßnahmen an Loggien, Balkonen, ...</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Fenster</td>
            <td>600 bis 800 €/m²</td>
            <td>
              <ul>
                <li>Fenstertausch zu hochwertiger zweifach- oder Dreifachverglasung entsprechend Effizienz-hausstandard EH 55 bis 85</li>
                <li>Ggf. Sanierung oder Neuerrichtung von Rollläden, ...</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Außentüren</td>
            <td>4000 bis 8000 €</td>
            <td>
              <ul>
                <li>Türentausch entsprechend Effizienz-hausstandard EH 55 bis 85</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Kellerdecke</td>
            <td>150 bis 250 €/m²</td>
            <td>
              <ul>
                <li>Dämmung mit 8 bis 12 cm entsprechend Effizienzhausstandard EH 55 bis 85</li>
                <li>Ggf. Dämmung hin zu angrenzenden Wänden z.B. im Treppenhaus</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Lüftung</td>
            <td>75 bis 125 €/m²</td>
            <td>
              <ul>
                <li>Errichtung einer mechanischen Lüftungsanlage mit Wärmerückgewinnung</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Photovoltaik</td>
            <td>1500 bis 2000 €/ KWp</td>
            <td>
              <ul>
                <li>Errichtung einer Photovoltaikanlage mit xxx kW 5 m² Dachfläche / KWp</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Solarthermie</td>
            <td>8000 bis 15000 €</td>
            <td>
              <ul>
                <li>Errichtung einer Solarthermieanlage, ggf. in Kombination mit Photovoltaik (PVT)</li>
              </ul>
            </td>
          </tr>
        </table>

      </div>
    </div>
  </div>
  <div class="error overlay" @click="showErrorOverlay(false)" :class="{ active: showError }">
    <div class="overlay-content">
      <h2>Es ist ein Fehler aufgetreten</h2>
      <p>{{errorMessage}}</p>
    </div>
  </div>
  <div class="header"></div>
  <div class="tab-nav">
    <button  @click="switchTab(0)" :class="[selectedTab === 0 ?  'active': '']">Eingabe</button>
    <button
        :disabled="!output"
        @click="switchTab(1)"
        :class="[selectedTab === 1 ?  'active': '', !output ? 'disabled': '']"
    >Ergebnisse</button>
  </div>
  <div class="tab input" :class="{ active : selectedTab === 0 }">
    <input-form @submit="submit" @change="change"/>
  </div>
  <div class="tab output" :class="{ active : selectedTab === 1 }">
    <h1>PLAN FÜR IHRE HAUSSANIERUNG – Ihr Ergebnis:</h1>
    <user-input-view v-if="input" :input="input"/>
    <result-view v-if="output" :data="output" @click:showInfo="showInfoOverlay(true)"/>
    <a href="" @click.prevent="showInfoDialog">HINWEISE ZUM PREISORIENTIERUNGSRECHNER</a>
    <a class="search-for-experts" href="https://experten.bz-af.de/" target="_blank"><img src="@/assets/search-for-experts.png" alt="Expertensuche"></a>
  </div>
  <my-dialog ref="dialog"/>
</template>
<script>
import InputForm from "@/components/InputForm.vue";
import APIService from "@/services/APIService";
import ResultView from "@/components/ResultView.vue";
import UserInputView from "@/components/UserInputView.vue";
import MyDialog from "@/components/MyDialog.vue";
// import {toast} from "vue3-toastify";
// import APIService from "@/services/APIService";

export default {
  name: "MainView",
  components: {MyDialog, InputForm, ResultView, UserInputView},
  data() {
    return {
      errorMessage: "",
      selectedTab: 0,
      input: undefined,
      output: undefined,
      inputChanged: false,
      showInfo: false,
      showError: false,
    }
  },
  methods: {
    printPage() {
      window.print();
    },
    contactPage() {
      this.$refs.dialog.showDialog("https://www.bz-af.de/kontakt/");
    },
    showInfoDialog() {
      this.$refs.dialog.showDialog("https://www.bz-af.de/hinweise-zum-preisorientierungsrechner/");
    },
    submit(input) {
      this.inputChanged = false
      this.input = input
      this.calculateData()
          .then(() => {
            window.scrollTo({ top: 0 });
            this.selectedTab = 1
          })
          .catch((error) => {
            this.errorMessage = error.message
            this.loading = false;
            this.showErrorOverlay(true)
          })
    },
    change(input) {
      this.input = {...input};
      this.inputChanged = true
    },
    showInfoOverlay(show) {
      this.showInfo = show
      if(show) {
        document.body.classList.add('fixed')
      } else {
        document.body.classList.remove('fixed')
      }
    },
    showErrorOverlay(show) {
      this.showError = show
      if(show) {
        document.body.classList.add('fixed')
      } else {
        document.body.classList.remove('fixed')
      }
    },
    async switchTab(tab) {
      if (this.selectedTab !== tab) {
        if(tab === 1 && !this.loading && this.inputChanged && this.output) {
          console.log("Reload Data")
          this.output = undefined
          await this.calculateData()
          this.selectedTab = tab
          this.inputChanged = false
        } else {
          this.selectedTab = tab
        }
      }
    },
    async calculateData() {
      // const errors = [
      //   {
      //     message: "Es ist ein Fehler aufgetreten",
      //   },
      //   {
      //     message: "Hier kommt noch ein Fehler bla blub.",
      //   }
      //   ,
      //   {
      //     message: "Sie haben einen Verbrauch von 50000 KWH angegeben. Nach unsere Plausibilitätsprüfung kann dieser Wert nicht stimmen.  Bitte prüfen sie ihre Eingaben und korrigieren sie ihre Eingabe.",
      //   }]
      this.loading = true
      this.output = await APIService.callCalculator({Input: this.input})
      // errors.forEach(error => {
      //   toast.warn(error.message, {
      //     autoClose: false,
      //     position: toast.POSITION.BOTTOM_CENTER,
      //   })
      // })

      this.loading = false
    }
  },
  computed: {
    loading: {
      get() {
        return this.$root.loading
      },
      set(value) {
        this.$root.loading = value
      }
    }
  }
}
</script>

<style scoped>
.Toastify__toast-container {
  width: 500px !important;
}
.search-for-experts img {
  max-width: 350px !important;
}
</style>