<template>
  <div id="wrapper" v-if="data">
    <h2>PLAN FÜR IHRE HAUSSANIERUNG</h2>
    <div class="table-section half">
      <h2>Investitionen für die Sanierung<img src="../assets/euro-sign-solid.svg" alt=""/></h2>
      <table class="effekte-finanz">
        <tbody>
        <tr>
          <td>Investitionen für die Sanierung vor Förderung</td>
          <td class="rechts">{{formatCurrency(mittleresSzenario.EntwicklungsSzenario[0].FinanzielleEffekte["InvestitionenFürDieSanierung"])}}</td>
        </tr>
        <tr>
          <td>Gesamtförderung</td>
          <td class="rechts">{{formatCurrency(mittleresSzenario.EntwicklungsSzenario[0].FinanzielleEffekte["GesamtFörderung"])}}</td>
        </tr>
        <tr>
          <td>
            <ul>
              <li>Davon Effizienzhausförderung nach BEG
              </li>
            </ul>
          </td>
          <td class="rechts">{{formatCurrency(mittleresSzenario.EntwicklungsSzenario[0].FinanzielleEffekte["GesamtFörderungBund"])}}</td>
        </tr>
        <tr>
          <td>
            <ul v-if="gesamtFörderungLokalText">
              <li>
                {{ gesamtFörderungLokalText }}
              </li>
            </ul>
          </td>
          <td class="rechts">{{gesamtFörderungLokalText ? formatCurrency(mittleresSzenario.EntwicklungsSzenario[0].FinanzielleEffekte["GesamtFörderungLokal"]) : "&nbsp;"}}</td>
        </tr>
        <tr>
          <td><b>Gesamtinvestition nach Förderung</b></td>
          <td class="rechts"><b>{{formatCurrency(mittleresSzenario.EntwicklungsSzenario[0].FinanzielleEffekte["InvestitionenNachFörderung"])}}</b></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="table-section half">
      <h2>Ökologische Effekte der energetischen Sanierung<img src="../assets/leaf-solid.svg" alt=""/></h2>
      <table class="effekte-oeko">
        <tbody>
        <tr>
          <td class="grau"></td>
          <td class="rechts grau kosten"><img src="../assets/house-circle-xmark-colour.svg" alt=""/><br>
            Ohne Sanierung</td>
          <td class="rechts grau kosten"><img src="../assets/house-circle-check-colour.svg" alt=""/><br>
            Mit Sanierung</td>

        </tr>
        <tr>
          <td class="enger">Energieeffizienzklasse</td>
          <td class="rot effizienz">{{basisSzenario.EntwicklungsSzenario[0].ÖkologischeEffekte["Effizienzklasse"]}}</td>
          <td class="gruen effizienz">{{mittleresSzenario.EntwicklungsSzenario[0].ÖkologischeEffekte["Effizienzklasse"]}}</td>

        </tr>
        <tr>
          <td>Wärmebedarf [kWh/(m²*a)]</td>
          <td class="rot">{{basisSzenario.EntwicklungsSzenario[0].ÖkologischeEffekte["Wärmebedarf"].toLocaleString()}}</td>
          <td class="gruen">{{mittleresSzenario.EntwicklungsSzenario[0].ÖkologischeEffekte["Wärmebedarf"].toLocaleString()}}</td>
        </tr>
        <tr>
          <td>CO2 Emissionen [t/a]</td>
          <td class="rot">{{basisSzenario.EntwicklungsSzenario[0].ÖkologischeEffekte["CO2Emmissionen"].toLocaleString()}}</td>
          <td class="gruen">{{mittleresSzenario.EntwicklungsSzenario[0].ÖkologischeEffekte["CO2Emmissionen"].toLocaleString()}}</td>
        </tr>
        <tr>
          <td>Förderklasse</td>
          <td class="rot">{{basisSzenario.EntwicklungsSzenario[0].ÖkologischeEffekte["Förderungsklasse"]}}</td>
          <td class="gruen">{{mittleresSzenario.EntwicklungsSzenario[0].ÖkologischeEffekte["Förderungsklasse"]}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="table-section half">
      <h2>Entwicklung der Energiekosten über 20 Jahre</h2>
      <table class="effekte-finanz-laufend">
        <tbody>
        <tr>
          <td class="grau"><h3>Entwicklung der Strom- und Gaspreise</h3></td>
          <td class="rechts grau kosten"><img src="../assets/house-circle-xmark-colour.svg" alt=""/><br>
            Ohne Sanierung</td>
          <td class="rechts grau kosten"><img src="../assets/house-circle-check-colour.svg" alt=""/><div class="tooltip">Durch den Eigenverbrauch und die Einspeisevergütung der Photovoltaikanlage reduzieren sich die Energiekosten sehr, bis hin zu höheren Erträgen als Energiekosten.</div><br>
            Mit Sanierung</td>
        </tr>
        <tr>
          <td class="grau mittig"><img src="../assets/chart-line-solid-straight.svg" alt=""/>Gleich bleibend<div class="tooltip">Aktuelle Energiepreise zum Stand der Berechnung</div></td>
          <td class="rechts rot">{{ formatCurrency(basisSzenario.EntwicklungsSzenario[0].FinanzielleEffekte["LaufendeEnergiekostenIn20Jahren"]) }}</td>
          <td class="rechts gruen">{{ formatCurrency(mittleresSzenario.EntwicklungsSzenario[0].FinanzielleEffekte["LaufendeEnergiekostenIn20Jahren"]) }}</td>
        </tr>
        <tr>
          <td class="grau mittig"><img src="../assets/chart-line-solid.svg" alt=""/>Moderat steigend<div class="tooltip">Moderater Anstieg der Gas- und Strompreise um 2% je Jahr, entsprechend einem Anstieg um 50% in den nächsten Jahren</div></td>
          <td class="rechts rot">{{ formatCurrency(basisSzenario.EntwicklungsSzenario[1].FinanzielleEffekte["LaufendeEnergiekostenIn20Jahren"]) }}</td>
          <td class="rechts gruen">{{ formatCurrency(mittleresSzenario.EntwicklungsSzenario[1].FinanzielleEffekte["LaufendeEnergiekostenIn20Jahren"]) }}</td>
        </tr>
        <tr>
          <td class="grau mittig"><img src="../assets/chart-line-high-solid.svg" alt=""/>Stark steigend<div class="tooltip">Moderater Anstieg der Strompreise um 2% je Jahr, stärkerer Anstieg der Gsspreise um 4% je Jahr, entsprechend einer Verdopplung in 20 Jahren</div></td>
          <td class="rechts rot">{{ formatCurrency(basisSzenario.EntwicklungsSzenario[2].FinanzielleEffekte["LaufendeEnergiekostenIn20Jahren"]) }}</td>
          <td class="rechts gruen">{{ formatCurrency(mittleresSzenario.EntwicklungsSzenario[2].FinanzielleEffekte["LaufendeEnergiekostenIn20Jahren"]) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="table-section half">
      <h2>Gesamtkosten: Investitionen nach Förderung zzgl. Energiekosten über 20 Jahre</h2>
      <table class="effekte-finanz-gesamt">
        <tbody>
        <tr>
          <td class="grau"><h3>Entwicklung der Strom- und Gaspreise</h3></td>
          <td class="rechts grau kosten"><img src="../assets/house-circle-xmark-colour.svg" alt=""/><br>
            Ohne Sanierung</td>
          <td class="rechts grau kosten"><img src="../assets/house-circle-check-colour.svg" alt=""/><div class="tooltip">Durch den Eigenverbrauch und die Einspeisevergütung der Photovoltaikanlage reduzieren sich die Energiekosten sehr, bis hin zu höheren Erträgen als Energiekosten.</div><br>
            Mit Sanierung</td>
        </tr>
        <tr>
          <td class="grau mittig"><img src="../assets/chart-line-solid-straight.svg" alt=""/>Gleich bleibend<div class="tooltip">Aktuelle Energiepreise zum Stand der Berechnung</div></td>
          <td class="rechts rot">{{ formatCurrency(basisSzenario.EntwicklungsSzenario[0].FinanzielleEffekte["GesamtkostenÜber20Jahre"]) }}</td>
          <td class="rechts gruen">{{ formatCurrency(mittleresSzenario.EntwicklungsSzenario[0].FinanzielleEffekte["GesamtkostenÜber20Jahre"]) }}</td>
        </tr>
        <tr>
          <td class="grau mittig"><img src="../assets/chart-line-solid.svg" alt=""/>Moderat steigend<div class="tooltip">Moderater Anstieg der Gas- und Strompreise um 2% je Jahr, entsprechend einem Anstieg um 50% in den nächsten Jahren</div></td>
          <td class="rechts rot">{{ formatCurrency(basisSzenario.EntwicklungsSzenario[1].FinanzielleEffekte["GesamtkostenÜber20Jahre"]) }}</td>
          <td class="rechts gruen">{{ formatCurrency(mittleresSzenario.EntwicklungsSzenario[1].FinanzielleEffekte["GesamtkostenÜber20Jahre"]) }}</td>
        </tr>
        <tr>
          <td class="grau mittig"><img src="../assets/chart-line-high-solid.svg" alt=""/>Stark steigend<div class="tooltip">Moderater Anstieg der Strompreise um 2% je Jahr, stärkerer Anstieg der Gsspreise um 4% je Jahr, entsprechend einer Verdopplung in 20 Jahren</div></td>
          <td class="rechts rot">{{ formatCurrency(basisSzenario.EntwicklungsSzenario[2].FinanzielleEffekte["GesamtkostenÜber20Jahre"]) }}</td>
          <td class="rechts gruen">{{ formatCurrency(mittleresSzenario.EntwicklungsSzenario[2].FinanzielleEffekte["GesamtkostenÜber20Jahre"]) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="table-section">
      <h2><span>Maßnahmenüberblick</span></h2>
      <table class="massnahmen">
        <tbody>
        <tr>
          <td class="zelle-halb">Heizungssystem<div class="tooltip">Über den Betrachtungszeitraum von 20 Jahren kann bei vorhandenen Systemen ein Austausch notwendig werden. Diese Investition ist berücksichtigt, auch wenn sie nicht sofort zum Tragen kommt</div></td>
          <td class="zelle-viertel">{{ mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["Heizung"].EnergieTräger }}</td>
          <td class="zelle-viertel rechts">{{ formatCurrency(mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["Heizung"].Kosten) }}</td>
        </tr>
        <tr>
          <td>Dämmung des Dachs</td>
          <td>{{ mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["Dach"].GüteText }}</td>
          <td class="rechts">{{ formatCurrency(mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["Dach"].Kosten) }}</td>
        </tr>
        <tr>
          <td>Dämmung der Außenwände</td>
          <td>{{ mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["Außenwand"].GüteText }}</td>
          <td class="rechts">{{ formatCurrency(mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["Außenwand"].Kosten) }}</td>
        </tr>
        <tr>
          <td>Dämmung der obersten Geschossdecke</td>
          <td>{{ mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["ObereGeschossdecke"].GüteText }}</td>
          <td class="rechts">{{ formatCurrency(mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["ObereGeschossdecke"].Kosten) }}</td>
        </tr>
        <tr>
          <td>Dämmung der Kellerdecke</td>
          <td>{{ mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["Kellerdecke"].GüteText }}</td>
          <td class="rechts">{{ formatCurrency(mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["Kellerdecke"].Kosten) }}</td>
        </tr>
        <tr>
          <td>Austausch der Fenster</td>
          <td>{{ mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["Fenster"].GüteText }}</td>
          <td class="rechts">{{ formatCurrency(mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["Fenster"].Kosten) }}</td>
        </tr>
        <tr>
          <td>Austausch der Haustüre</td>
          <td>{{ mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["Tür"].GüteText }}</td>
          <td class="rechts">{{ formatCurrency(mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["Tür"].Kosten) }}</td>
        </tr>
        <tr>
          <td>Einbau einer Lüftungsanlage mit Wärmerückgewinnung</td>
          <td>{{ mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["Lüftung"].LüftungsArt }}</td>
          <td class="rechts">{{ formatCurrency(mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["Lüftung"].Kosten) }}</td>
        </tr>
        <tr>
          <td>Installation einer Photovoltaikanlage mit einer Leistung [KWp] von</td>
          <td>{{ mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["PV"].Size.toLocaleString() }}</td>
          <td class="rechts">{{ formatCurrency(mittleresSzenario.EntwicklungsSzenario[0].OutputKostenDerBauteile["PV"].Kosten) }}</td>
        </tr>
        </tbody>
      </table>
      <address></address>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResultView",
  props: {
    data: {
      type: Object,
      required: true,
      qualityTextTranslation: {
        "Dach": {
          "Nichts": "Keine Maßnahme",
          "Einfach": "nach den gesetzlichen Mindestanforderungen",
          "Gut": "nach Effizienzhausstandard",

        },
        "Außenwände": {
          "Nichts": "Keine Maßnahme",
          "Einfach": "nach den gesetzlichen Mindestanforderungen",
          "Gut": "nach Effizienzhausstandard",

        },
        "obere Geschossdecke": {
          "Nichts": "Keine Maßnahme",
          "Einfach": "nach den gesetzlichen Mindestanforderungen",
          "Gut": "nach Effizienzhausstandard",

        },
        "Kellerdecke": {
          "Nichts": "Keine Maßnahme",
          "Einfach": "nach den gesetzlichen Mindestanforderungen",
          "Gut": "nach Effizienzhausstandard",

        },
        "Fenster": {
          "Nichts": "Keine Maßnahme",
          "Einfach": "neue zweifachverglaste Fenster",
          "Gut": "neue dreifachverglaste Fenster",

        },
        "Türen": {
          "Nichts": "Keine Maßnahme",
          "Einfach": "nach den gesetzlichen Mindestanforderungen",
          "Gut": "nach Effizienzhausstandard",

        },
      }
    }
  },
  methods: {
    formatCurrency(number) {
      return new Intl.NumberFormat(
          'de-DE', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(number)
    },
    getSzenario(name) {
      const index = this.data.Output.Zuordnungen.find(scenario => scenario.Name === name).Szenario
      console.log("Szenario-Index", name, index)
      return this.data.Output.Szenarien[index]
    }
  },
  computed: {
    getQualityText(area, value) {
      return this.qualityTextTranslation[area][value]
    },
    gesamtFörderungLokalText() {
      return this.mittleresSzenario.EntwicklungsSzenario[0].FinanzielleEffekte["FörderungLokalText"]
    },
    basisSzenario() {
     return this.getSzenario('Basis')
    },
    mittleresSzenario() {
      return this.getSzenario('Mittleres Szenario')
    }
  },
}
</script>

<style scoped>
</style>