<template>
  <form @submit.prevent="$emit('submit', input)">
    <div id="wrapper">
      <h1>PREISORIENTIERUNGSRECHNER</h1>
      <!-- Angaben Objekt -->
      <div class="form-section" id="gebaeude">
        <h2 class="gebaeude">Ihr Wohngebäude</h2>
        <p>
          Diese Angaben sind Notwendig als Grundlage für die Berechnungen.<br>
          Bitte auch bei Eigentümergemeinschaften immer die Werte für das gesamte Gebäude eingeben, nicht nur für eine einzelne Wohnung
        </p>
        <div class="input-group">
          <input @change="inputChanged" v-model="input.Adresse.Ort" name="Adresse.Ort" id="Adresse.Ort" type="text" required="required" class="input">
          <label for="Adresse.Ort" class="placeholder">Ort</label>
        </div>
        <div class="input-group">
          <input @change="inputChanged" v-model="input.Adresse.PostCode" name="Adresse.PostCode" type="text" required="required" class="input" id="Adresse.PostCode">
          <label for="Adresse.PostCode" class="placeholder">Postleitzahl</label>
          <div data-lastpass-icon-root="true" style="position: relative !impOrtant; height: 0px !impOrtant; width: 0px !impOrtant; float: left !impOrtant;"></div></div>
        <div class="select-group">
          <div class="tooltip">An wie vielen Seiten ist das Haus an ein anderes angebaut? Ein Reihenendhaus entspricht einer Doppelhaushälfte</div>
          <select  v-model="input.GebäudeTyp" :class="{selected: input.GebäudeTyp}" name="gebäudeTyp" id="gebäudeTyp" class="floating-select" onclick="this.setAttribute('value', this.value);" value="">
            <option selected=""></option>
            <option value="Freistehend">Freistehend</option>
            <option value="Doppelhaus">Doppelhaus</option>
            <option value="Mittelhaus">Mittelhaus</option>
          </select>
          <label for="gebäudeTyp">Gebäudetyp</label>
        </div>
        <div class="select-group">
          <div class="tooltip">Ist das Dach flach oder schräg?</div>
          <select v-model="input.Dachform" :class="{selected: input.Dachform}" name="Dachform" id="Dachform" class="floating-select" onclick="this.setAttribute('value', this.value);" value="">
            <option selected=""></option>
            <option value="Flachdach">Flachdach</option>
            <option value="Satteldach">Satteldach</option>
          </select>
          <label for="Dachform">Dachform</label>
        </div>
        <div class="input-group">
          <input  @change="inputChanged" v-model.number="input.Baujahr" name="Baujahr" type="text" required="required" class="input" id="Baujahr">
          <label for="Baujahr" class="placeholder">Baujahr</label>
        </div>
        <div class="spacer"> </div>
        <div class="input-group">
          <input  @change="inputChanged" v-model.number="input.AnzahlWohneinheiten" name="AnzahlWohneinheiten" type="text" required="required" class="input" id="AnzahlWohneinheiten">
          <label for="AnzahlWohneinheiten" class="placeholder">Anzahl an Wohneinheiten</label>
        </div>
        <div class="input-group">
          <input  @change="inputChanged" v-model.number="input.BeheizteEtagenAnzahl" name="BeheizteEtagenAnzahl" type="text" required="required" class="input" id="BeheizteEtagenAnzahl">
          <label for="BeheizteEtagenAnzahl" class="placeholder">Anzahl beheizter Vollgeschosse</label>
        </div>
        <div class="input-group">
          <input  @change="inputChanged" v-model.number="input.Verbrauch.AnzahlBewohner" name="verbrauch.anzahlBewohner" type="text" required="required" class="input" id="verbrauch.anzahlBewohner">
          <label for="verbrauch.anzahlBewohner" class="placeholder">Anzahl an Bewohnern</label>
        </div>
        <div class="input-group">
          <input  @change="inputChanged" v-model.number="input.Wohnfläche" name="wohnfläche" type="text" required="required" class="input" id="wohnfläche">
          <label for="wohnfläche" class="placeholder">Beheizte Wohnfläche in m²</label>
        </div>
        <div class="select-group">
          <div class="tooltip">"Nein" bedeutet, es sind nur Räume mit geraden Wänden bewohnt.<br>
            "Ohne Spitze" bedeutet, es sind Dachräume bewohnt, darüber befindet sich jedoch noch ein kalter Spitzboden.<br>
            "Bis in die Spitze" bedeutet, dass über den beheizten Wohnräumen keine kalten Bereiche mehr sind.
          </div>
          <select v-model="input.DachgeschossBewohnt" :class="{selected: input.DachgeschossBewohnt}" :disabled="input.Dachform === 'Flachdach'" name="dachgeschossBewohnt" id="dachgeschossBewohnt" class="floating-select" onclick="this.setAttribute('value', this.value);" value="">
            <option selected=""></option>
            <option value="BisInDieSpitze">Bis in die Spitze</option>
            <option value="JaOhneSpitze">Ja, ohne Spitze</option>
            <option value="Nein">Nein</option>
          </select>
          <label for="dachgeschossBewohnt"> Dachgeschoss bewohnt?</label>
        </div>
        <div class="checkbox-group">
          <label for="kellerVorhanden" class="placeholder">Keller vorhanden?</label>
          <input  @change="inputChanged" v-model="input.KellerVorhanden" name="kellerVorhanden" id="kellerVorhanden" type="checkbox">
        </div>
        <div class="checkbox-group">
          <div class="tooltip">
            Der Zeitpunkt des Eigentümerwechsels hat Auswirkungen auf verpflichtend durchzuführende Sanierungsmaßnahmen
          </div>
          <label for="hatEigentumsWechselNach2002" class="placeholder">Eigentümerwechsel nach 2002?</label>
          <input  @change="inputChanged" v-model="input.HatEigentumsWechselNach2002" name="hatEigentumsWechselNach2002" id="hatEigentumsWechselNach2002" type="checkbox">
        </div>
        <div class="spacer"> </div>
      </div>

      <!-- Angaben Energie -->
      <div class="form-section" id="energie">
        <h2 class="energie">Ihre Energieversorung</h2>
        <p>Wie ist Ihre aktuelle Energieversorgung? Diese Angaben sind ebenfalls für die Berechnung notwendig.</p>
        <div class="select-group">
          <select v-model="input.HeizungsArt" :class="{selected: input.HeizungsArt}" name="heizungsArt" id="heizungsArt" class="floating-select" onclick="this.setAttribute('value', this.value);" value="">
            <option selected=""></option>
            <option value="Fussboden">Fußbodenheizung</option>
            <option value="Heizkörper">Heizkörper</option>
            <option value="Zimmerofen">Einzelöfen / Nachtspeicheröfen</option>
          </select>
          <label for="heizungsArt">Wärmeabgabe durch:</label>
        </div>
        <div class="select-group">
          <select v-model.number="input.EnergieTräger" :class="{selected: input.EnergieTräger}" name="energieTräger" id="energieTräger" class="floating-select" onclick="this.setAttribute('value', this.value);" value="">
            <option selected=""></option>
            <option value="0">Wärmepumpe</option>
            <option value="1">Strom</option>
            <option value="2">Gas</option>
            <option value="3">Öl</option>
            <option value="4">Holz</option>
            <option value="5">Fernwärme</option>
          </select>
          <label for="energieTräger">Energieträger</label>
        </div>
        <div class="input-group">
          <input @change="inputChanged" v-model.number="input.Verbrauch.BaujahrHeizung" name="verbrauch.baujahrHeizung" type="text" required="required" class="input" id="verbrauch.baujahrHeizung">
          <label for="verbrauch.baujahrHeizung" class="placeholder">Baujahr der Heizung</label>
        </div>
        <div class="select-group">
          <select v-model="input.TrinkwasserErwärmung" :class="{selected: input.TrinkwasserErwärmung}" name="trinkwasserErwärmung" id="trinkwasserErwärmung" class="floating-select" onclick="this.setAttribute('value', this.value);" value="">
            <option selected=""></option>
            <option value="Zentralheizung">Zentralheizung</option>
            <option value="DezentralerDurchlauferhitzer">Dezentraler Durchlauferhitzer</option>
          </select>
          <label for="trinkwasserErwärmung">Warmwasserbereitung</label>
        </div>
        <div class="select-group">
          <select v-model="input.LeitungsAnschlüsse" :class="{selected: input.LeitungsAnschlüsse}" name="leitungsAnschlüsse" id="leitungsAnschlüsse" class="floating-select" onclick="this.setAttribute('value', this.value);" value="">
            <option selected=""></option>
            <option value="Gas">Gas</option>
            <option value="Fernwärme">Fernwärme</option>
            <option value="Beides">Beides</option>
            <option value="NichtsDavon">Nichts davon</option>
          </select>
          <label for="leitungsAnschlüsse">Mögliche Leitungsanschlüsse</label>
        </div>
        <div class="checkbox-group">
          <div class="tooltip">
            Wenn schon PV auf dem Dach ist, kann keine weitere dazugebaut werden.<br>
            Die bestehende PV-Anlage wird in der Berechnung nicht berücksichtigt, bei "Stromverbrauch" bitte den Reststrombezug eintragen.
          </div>
          <label for="hatPVAnlage" class="placeholder">Photovoltaik vorhanden?</label>
          <input @change="inputChanged" v-model="input.HatPVAnlage" name="hatPVAnlage" id="hatPVAnlage" type="checkbox">
        </div>
        <div class="input-group">
          <div class="tooltip">
            Stromverbrauch des Hauseigentümers. Der Verbrauch von vermieteten Wohnungen wird nicht berücksichtigt.<br>
            Bei vollständig vermieteten Häusern bitte eine Null eintragen.</div>
          <input  @change="inputChanged" v-model.number="input.Verbrauch.Stromverbrauch" name="verbrauch.stromverbrauch" type="text" required="required" class="input" id="verbrauch.stromverbrauch">
          <label for="verbrauch.stromverbrauch" class="placeholder">Stromverbrauch [kWh/Jahr]</label>
        </div>
        <div class="input-group">
          <input  @change="inputChanged" v-model.number="input.Verbrauch.Wärmeverbrauch" :disabled="input.EnergieTräger === null" name="verbrauch.wärmeverbrauch" type="text" required="required" class="input" id="verbrauch.wärmeverbrauch">
          <label for="verbrauch.wärmeverbrauch" class="placeholder">{{labelEnergieTräger(input.EnergieTräger)}}</label>
        </div>
      </div>
    </div>
    <button :disabled="loading" type="submit">Berechnung starten</button>
  </form>
  <button v-if="isTestMode" id="submit-test-data" @click="input=testInput; $emit('submit', testInput)">Test Kalkulation</button>
</template>

<script>
import {labelEnergieTräger} from "@/utils/TextTranslation";
export default
{
  name: "InputForm",
  emits: ['submit', 'change'],
  data() {
    return {
      testInput:  {
        "Adresse": {
            "PostCode": "70186",
            "Ort": "Stuttgart",
          },
          "GebäudeTyp": "Freistehend",
          "Dachform": "Satteldach",
          "KellerVorhanden": true,
          "HeizungsArt": "Heizkörper",
          "DachgeschossBewohnt": "JaOhneSpitze",
          "Wärmeerzeuger": "Zentral",
          "EnergieTräger": "Öl",
          "TrinkwasserErwärmung": "Zentralheizung",
          "LeitungsAnschlüsse": "Gas",
          "HatPVAnlage": false,
          "HatEigentumsWechselNach2002": true,
          "Baujahr": 1975,
          "BeheizteEtagenAnzahl": 2.0,
          "NehmeStandartWennNichtPlausibel": false,
          "Wohnfläche": 160.0,
          "AnzahlWohneinheiten": 1,
          "Verbrauch": {
            "AnzahlBewohner": 4,
            "Stromverbrauch": 4000,
            "Wärmeverbrauch": 3700,
            "WärmeverbrauchEinheit": null,
            "BaujahrHeizung": 2015,
            "EnergieEffizienzKlasse": null
          },
          "ZustandDerBauteile": {
            "Dach": {
              "Typ": "Dach",
              "BauteilAlter": "WieBaujahrOderUnbekannt",
              "GüteBeiEinbau": "Unbekannt",
              "Fläche": null,
              "ModernisierungsBedarf": "Möglich"
            },
            "Außenwand": {
              "Typ": "Außenwand",
              "BauteilAlter": "WieBaujahrOderUnbekannt",
              "GüteBeiEinbau": "Unbekannt",
              "Fläche": null,
              "ModernisierungsBedarf": "Möglich"
            },
            "ObereGeschossdecke": {
              "Typ": "ObereGeschossdecke",
              "BauteilAlter": "WieBaujahrOderUnbekannt",
              "GüteBeiEinbau": "Unbekannt",
              "Fläche": null,
              "ModernisierungsBedarf": "Möglich"
            },
            "Kellerdecke": {
              "Typ": "Kellerdecke",
              "BauteilAlter": "WieBaujahrOderUnbekannt",
              "GüteBeiEinbau": "Unbekannt",
              "Fläche": null,
              "ModernisierungsBedarf": "Möglich"
            },
            "Fenster": {
              "Typ": "Fenster",
              "BauteilAlter": "WieBaujahrOderUnbekannt",
              "GüteBeiEinbau": "Unbekannt",
              "Fläche": null,
              "ModernisierungsBedarf": "Möglich"
            },
            "Tür": {
              "Typ": "Tür",
              "BauteilAlter": "WieBaujahrOderUnbekannt",
              "GüteBeiEinbau": "Unbekannt",
              "Fläche": null,
              "ModernisierungsBedarf": "Möglich"
            },
            "Lüftung": {
              "Typ": "Lüftung",
              "LüftungsArtIst": "Fenster",
              "LüftungsArtNachSanierung": "Fenster",
              "Fläche": null,
              "ModernisierungsBedarf": "Möglich"
            },
            "PV": {
              "Typ": "PV",
              "Fläche": null,
              "ModernisierungsBedarf": "Möglich"
            }
          },
          "DatabaseProfile": "None",
        "EnergiePreisSzenarien":[{"Name":"Default"},{"Name":"Szenario1"},{"Name":"Szenario2"}]
        },
      input: {
        "Adresse": {
          "PostCode": null,
          "Ort": null,
          "Straße": null,
          "Hausnummer": null
        },
        "GebäudeTyp": null,
        "Dachform": null,
        "KellerVorhanden": false,
        "HeizungsArt": null,
        "DachgeschossBewohnt": null,
        "Wärmeerzeuger": "Zentral", // Muss noch eingetragen werden
        "EnergieTräger": null,
        "TrinkwasserErwärmung": null,
        "LeitungsAnschlüsse": null,
        "HatPVAnlage": false,
        "HatEigentumsWechselNach2002": false,
        "Baujahr": null,
        "BeheizteEtagenAnzahl": null,
        "NehmeStandartWennNichtPlausibel": false,
        "Wohnfläche": null,
        "AnzahlWohneinheiten": null,

        "Verbrauch": {
          "AnzahlBewohner": null,
          "Stromverbrauch": null,
          "Wärmeverbrauch": null,
          "BaujahrHeizung": null,
          "EnergieEffizienzKlasse": 250.0
        },
        "ZustandDerBauteile": {
          "Dach": {
            "Typ": "Dach",
            "BauteilAlter": "WieBaujahrOderUnbekannt",
            "GüteBeiEinbau": "Unbekannt",
            "Fläche": null,
            "ModernisierungsBedarf": "Möglich"
          },
          "Außenwand": {
            "Typ": "Außenwand",
            "BauteilAlter": "WieBaujahrOderUnbekannt",
            "GüteBeiEinbau": "Unbekannt",
            "Fläche": null,
            "ModernisierungsBedarf": "Möglich"
          },
          "ObereGeschossdecke": {
            "Typ": "ObereGeschossdecke",
            "BauteilAlter": "WieBaujahrOderUnbekannt",
            "GüteBeiEinbau": "Unbekannt",
            "Fläche": null,
            "ModernisierungsBedarf": "Möglich"
          },
          "Kellerdecke": {
            "Typ": "Kellerdecke",
            "BauteilAlter": "WieBaujahrOderUnbekannt",
            "GüteBeiEinbau": "Unbekannt",
            "Fläche": null,
            "ModernisierungsBedarf": "Möglich"
          },
          "Fenster": {
            "Typ": "Fenster",
            "BauteilAlter": "WieBaujahrOderUnbekannt",
            "GüteBeiEinbau": "Unbekannt",
            "Fläche": null,
            "ModernisierungsBedarf": "Möglich"
          },
          "Tür": {
            "Typ": "Tür",
            "BauteilAlter": "WieBaujahrOderUnbekannt",
            "GüteBeiEinbau": "Unbekannt",
            "Fläche": null,
            "ModernisierungsBedarf": "Möglich"
          },
          "Lüftung": {
            "Typ": "Lüftung",
            "LüftungsArtIst": "Fenster", // Needs to be fixed
            "LüftungsArtNachSanierung": "Fenster",
            "Fläche": null,
            "ModernisierungsBedarf": "Möglich"
          },
          "PV": {
            "Typ": "PV",
            "Fläche": null,
            "ModernisierungsBedarf": "Möglich"
          }
        },
        "EnergiePreisSzenarien":[{"Name":"Default"},{"Name":"Szenario1"},{"Name":"Szenario2"}],
      },
      showOptional: false,
      result: undefined
    }
  },
  methods: {
    inputChanged() {
      this.$emit('change', this.input)
    },
    labelEnergieTräger,
  },
  computed: {
    loading() {
      return this.$root.loading
    },
    vorschlagStromverbrauch() {
      const anzahlBewohner = this.input.Verbrauch.AnzahlBewohner;
      let result = null
      switch(anzahlBewohner) {
        case 1: result = 1500;
          break;
        case 2: result = 2500;
          break;
        case 3: result = 3500;
          break;
        default: result = anzahlBewohner ? 3500 + (anzahlBewohner - 3) * 750 : null
      }
      return result;
    },
    isTestMode() {
      const params = new URLSearchParams(window.location.search)
      return Array.from(params.keys()).includes("test")
    }
  },
  watch: {
    "input.Verbrauch.AnzahlBewohner":  function() {
      this.input.Verbrauch.Stromverbrauch = this.vorschlagStromverbrauch
    },
    "input.EnergieTräger":  function() {
      this.input.Verbrauch.Wärmeverbrauch= null
    },
    "input.Dachform": function(newValue) {
      if(newValue === "Flachdach") {
        this.input.DachgeschossBewohnt = "Nein"
      }
    },
  },
}
</script>
<style scoped>
</style>