import { createApp } from 'vue'
import App from './App.vue'
import Vue3Toasity, { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css';

const app = createApp(App)
app.use(
    Vue3Toasity,
    {
        autoClose: false,
        position: toast.POSITION.BOTTOM_CENTER,
    },
)
app.mount('#app')