<template>
  <div v-if="input" class="input">
    <h2>Ihre Angaben</h2>
    <div class="building">
      <h3>Angaben zum Wohngebäude</h3>
      <div class="city"><div class="label">Ort:</div> {{input.Adresse.Ort}}</div>
      <div class="post-code"><div class="label">Postleitzahl:</div>{{input.Adresse.PostCode}}</div>
      <div class="building-type"><div class="label">Gebäudetyp:</div> {{input.GebäudeTyp}}</div>
      <div class="roof-shape"><div class="label">Dachform:</div> {{input.Dachform}}</div>
      <div class="construction-year"><div class="label">Baujahr:</div> {{input.Baujahr}}</div>

      <div class="number-of-residential-units"><div class="label">Anzahl an Wohneinheiten:</div>{{input.AnzahlWohneinheiten}}</div>
      <div class="heated-floors"><div class="label">Anzahl beheizter Vollgeschosse:</div>{{input.BeheizteEtagenAnzahl}}</div>
      <div class="number-of-residents"><div class="label">Anzahl an Bewohnern:</div>{{input.Verbrauch.AnzahlBewohner}}</div>
      <div class="living-space"><div class="label">Beheizte Wohnfläche in m²:</div>{{input.Wohnfläche}}</div>
      <div class="inhabited-attic"><div class="label">Dachgeschoss bewohnt?:</div>{{textDachgeschossBewohnt(input.DachgeschossBewohnt)}}</div>
      <div class="cellar-available"><div class="label">Keller vorhanden?:</div>{{boolText(input.KellerVorhanden)}}</div>
      <div class="has-chage-of-ownership-after-2002"><div class="label">Eigentümerwechsel nach 2002?:</div> {{boolText(input.HatEigentumsWechselNach2002)}}</div>
    </div>
    <div class="energy">
      <h3>Angaben zur Energieversorung</h3>
      <div class="type-of-heating"><div class="label">Wärmeabgabe durch:</div> {{input.HeizungsArt}}</div>
      <div class="energy-source"><div class="label">Energieträger:</div> {{energieTrägerText(input.EnergieTräger)}}</div>
      <div class="year-of-construction-heating"><div class="label">Baujahr der Heizung:</div> {{input.Verbrauch.BaujahrHeizung}}</div>
      <div class="drinking-water-heating"><div class="label">Warmwasserbereitung:</div> {{input.TrinkwasserErwärmung}}</div>
      <div class="line-connections"><div class="label">Mögliche Leitungsanschlüsse:</div>{{input.LeitungsAnschlüsse}}</div>
      <div class="has-pv-system"><div class="label">Photovoltaik vorhanden?:</div>{{boolText(input.HatPVAnlage)}}</div>
      <div class="power-consumption"><div class="label">Stromverbrauch (kWh/Jahr):</div>{{input.Verbrauch.Stromverbrauch}}</div>
      <div class="heat-consumption"><div class="label">{{labelEnergieTräger(input.EnergieTräger)}}:</div>{{input.Verbrauch.Wärmeverbrauch}}</div>
    </div>
    <div class="components">
      <h3>Zusätzliche Angaben zu einzelnen Bauteilen</h3>
      <component-view label="Dach" :component="input"/>
      <component-view label="Außenwand" :component="input.ZustandDerBauteile.Außenwand"/>
      <component-view label="Obere Geschossdecke" :component="input.ZustandDerBauteile.ObereGeschossdecke"/>
      <component-view label="Kellerdecke" :component="input.ZustandDerBauteile.Kellerdecke"/>
      <component-view label="Fenster" :component="input.ZustandDerBauteile.Fenster"/>
      <component-view label="Tür" :component="input.ZustandDerBauteile.Tür"/>
      <component-view label="Lüftung" :component="input.ZustandDerBauteile.Lüftung"/>
    </div>
  </div>
</template>
<script>
import ComponentView from "@/components/ComponentView.vue";
import {labelEnergieTräger, energieTrägerText, boolText, textDachgeschossBewohnt} from "@/utils/TextTranslation";
export default {
  name: "UserInputView",
  components: {ComponentView},
  props: {
    input: {
      type: Object,
      required: true,
    }
  },
  methods: {
    labelEnergieTräger,
    energieTrägerText,
    boolText,
    textDachgeschossBewohnt,
  },
}
</script>

<style scoped>
</style>