<template>
  <div class="component">
    <h4>{{label}}</h4>
    <div v-if="component.Typ ==='Lüftung'" class="ventilatio-type">
      <div class="label">Art der Lüftung:</div>
      {{component.LüftungsArtIst}}
    </div>
    <template v-else>
      <div class="age">
        <div class="label">Materialalter (letzte Modernisierung):</div>
        {{ componentAgeText(component.BauteilAlter) }}</div>
      <div class="quality">
        <div class="label">Güte der Bauteile bei Einbau:</div>
        {{ component.GüteBeiEinbau }}</div>
      <div class="surface"><div class="label">Bauteilfläche [m²]:</div>
        {{ component.Fläche }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ComponentView",
  props: {
    component: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  methods: {
    componentAgeText(val) {
      console.log("Value", val)
      const translations = {
        "WieBaujahrOderUnbekannt": "Wie Baujahr oder unbekannt",
        "Seit2010": "Seit 2010",
        "Seit2002Bis2009": "2002 - 2009",
        "Seit1995Bis2001": "1995 - 2001",
        "Seit1984Bis1994": "1984 - 1994",
        "Seit1979Bis1983": "1979 - 1983",
        "Bis1978": "Bis 1978",
      }
      return translations[val]
    }
  }
}
</script>

<style scoped>

</style>