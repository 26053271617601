<script setup></script>
<template>
  <div>
    <main-view/>
  </div>
</template>

<script>
import MainView from "@/components/MainView.vue";
import './assets/css/main.css';
import { useTitle, useFavicon } from '@vueuse/core'

export default {
  name: 'App',
  components: {
    MainView,
  },
  data() {
    return {
      loading: false,
    }
  },
  mounted() {
    const favicon = useFavicon()
    favicon.value = process.env.VUE_APP_FAVICON ?? 'favicon.ico'
    console.log("Favicon", favicon, process.env.VUE_APP_TITLE )
    const title = useTitle()
    title.value = process.env.VUE_APP_TITLE ?? 'Regiozentral'
    console.log("Title", title.value)
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
