import axios from "axios";

const config = {
    baseURL: 'https://api.klimasparplan.de/api',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json-patch+json',
    },
    timeout: 20000
};
const apiClient = axios.create(config)
export default {
    callCalculator(input) {
       return apiClient.post('calculate', input).then(res => res.data)
    }
}