<template>
  <div class="overlay" v-if="show">
    <button type="button" class="close" @click="hideDialog">X</button>
    <iframe style="width: 100%; height: 100%; z-index: 50" :src="url"></iframe>
  </div>
</template>
<script>
export default {
  name: "MyDialog",
  data() {
    return {
      show: false,
      url: undefined,
      oldOverflow: undefined
    }
  },
  methods: {
    showDialog(url) {
      this.url = url;
      this.show = true;
      this.show = true
      const body = document.getElementsByTagName('body')[0];
      this.oldOverflow =  body.style.overflow;
      body.style.overflow = 'hidden';


      const element = window?.parent?.document?.getElementById("s3m_overlay_frame_1")
      console.log("Element", element, window?.parent?.document)
      if(element) {
        element.classList.add("second-overlay-active");
      }
    },
    hideDialog() {
      this.show = false;
      const body = document.getElementsByTagName('body')[0];
      body.style.overflow = this.oldOverflow;

      const element = window?.parent?.document?.getElementById("s3m_overlay_frame_1")
      console.log("Element", element, window?.parent?.document)
      if(element) {
        element.classList.remove("second-overlay-active");
      }
    }
  }
}
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-style: none;
  background-color: white;
  z-index: 1000000;
}
.close {
  position: fixed;
  right: 0;
  top: 0;
}
</style>